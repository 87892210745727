import { graphql, PageProps } from "gatsby";
import { FC } from "react";

import { PageMeta } from "@/components/elements";
import { MainLayout } from "@/components/layout";
import { LegalInfo, LegalInfoProps } from "@/features/legal-info";
import { NavbarLink } from "@/features/navbar";

interface QueryResult {
  navbar: {
    frontmatter: {
      navbarLinks: NavbarLink[];
    };
  };
  legalInfo: {
    html: string;
    frontmatter: LegalInfoProps;
  };
}

const LegalInfoTemplate: FC<PageProps<QueryResult>> = ({
  data,
}: PageProps<QueryResult>): JSX.Element => {
  const { pageTitle, disclaimerMd, descr, color, bgColor, contentMd } = data.legalInfo.frontmatter;
  const { navbarLinks } = data.navbar.frontmatter;

  return (
    <MainLayout navbarLinks={navbarLinks}>
      <PageMeta isTemplate title={pageTitle} description={descr} />
      <LegalInfo
        pageTitle={pageTitle}
        contentMd={contentMd}
        disclaimerMd={disclaimerMd}
        color={color}
        bgColor={bgColor}
      />
    </MainLayout>
  );
};

export const query = graphql`
  query LegalInfoPageQuery($id: String) {
    navbar: markdownRemark(fields: { collection: { eq: "navbar" } }) {
      frontmatter {
        navbarLinks {
          label
          url
        }
      }
    }
    legalInfo: markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        descr
        color
        bgColor
        disclaimerMd
        contentMd
      }
    }
  }
`;

export default LegalInfoTemplate;
