import { PreviewTemplateComponentProps } from "netlify-cms-core";
import { FC, useMemo } from "react";

import { deepParseHtml } from "@/utils/deep-parse-html";

import Article, { ArticleProps } from "./Main";

const ArticlePreview: FC<PreviewTemplateComponentProps> = ({
  getAsset,
  entry,
}: PreviewTemplateComponentProps): JSX.Element => {
  const props = useMemo(() => {
    const props: ArticleProps = deepParseHtml(entry.get("data").toJS());

    props.pageTitle = props.pageTitle || "Enter Page Title";
    props.headlineCard = props.headlineCard ?? {};
    props.headlineCard.headline = props.headlineCard.headline || "Enter headline";
    props.headlineCard.textMd = props.headlineCard.textMd || "Enter headline text";
    props.mainSection = props.mainSection ?? {
      header: "Enter main section headline",
      text: "Enter main section text",
    };
    props.subSections = props.subSections?.filter((subSection) => !!subSection.header) ?? [];

    props.headlineCard.bgImg = getAsset(props.headlineCard.bgImg as string).toString();
    props.subSections = props.subSections.map((section) => ({
      ...section,
      image: getAsset(section.image as string).toString(),
    }));

    return props;
  }, [entry, getAsset]);

  return <Article {...props} />;
};

export default ArticlePreview;
