import { FC, useMemo } from "react";

import { CmsImage, Html, ParallaxTile } from "@/components/elements";
import { ContentContainer, ContentLayout } from "@/components/layout";
import { CmsImageData } from "@/types/cms-image-data";
import { getGatsbyImageData } from "@/utils/get-gatsby-image-data";

export interface ArticleProps {
  pageTitle: string;
  headlineCard: {
    headline: string;
    bgImg: CmsImageData;
    textMd: string;
  };
  mainSection: {
    header: string;
    textMd: string;
  };
  subSections: {
    image: CmsImageData;
    header: string;
    textMd: string;
  }[];
  descr?: string;
}

const Article: FC<ArticleProps> = ({
  pageTitle,
  headlineCard,
  mainSection,
  subSections,
}: ArticleProps): JSX.Element => {
  const { cmsHeadlineBgImg, cmsSubSectionImages } = useMemo(() => {
    return {
      cmsHeadlineBgImg: getGatsbyImageData(headlineCard.bgImg),
      cmsSubSectionImages: subSections?.map(({ image }) => getGatsbyImageData(image)),
    };
  }, [headlineCard.bgImg, subSections]);

  return (
    <ContentLayout>
      <ParallaxTile
        bgSlot={
          <CmsImage
            objectFit={"cover"}
            objectPosition={"top"}
            className={"h-[25.6em] md:min-h-[25.6em] w-full object-cover object-top"}
            image={cmsHeadlineBgImg}
            alt={headlineCard.headline}
          />
        }
        fgSlot={
          <div
            className={
              "bg-white opacity-90 w-full md:w-[39.1em] min-h-[28.8em] p-[1em] md:p-[3em] md:mx-[3em]"
            }
          >
            <div className={"text-slate-900 uppercase tracking-[0.3em] text-lg"}>{pageTitle}</div>
            <h1 className={"m-0 text-[30px] md:text-[40px] font-bold leading-[1.2em] pt-8 pb-4"}>
              {headlineCard.headline}
            </h1>
            <Html>{headlineCard.textMd}</Html>
          </div>
        }
      />
      <ContentContainer>
        <article>
          <section className={"pt-[3em] pb-[2em] sm:pb-[4em]"}>
            <h2 className={"text-[2.25em] font-bold mb-5"}>{mainSection.header}</h2>
            <Html>{mainSection.textMd}</Html>
          </section>
          <div className={"grid bg-white grid-cols-1 lg:grid-cols-2"}>
            {subSections?.map((section, index) => (
              <section
                key={section.header}
                className={"mr-0 mt-10 lg:mr-10 lg:mt-0 last:mr-0"}
                style={{
                  gridColumn: subSections?.length > 1 ? "span 1 / span 1" : "span 2 / span 2",
                }}
              >
                <CmsImage
                  className={"h-[460px] w-full object-cover"}
                  image={cmsSubSectionImages[index]}
                  alt={section.header}
                />
                <div className={"text-[2.12em] font-bold pt-8 pb-4"}>{section.header}</div>
                <Html>{section.textMd}</Html>
              </section>
            ))}
          </div>
        </article>
      </ContentContainer>
    </ContentLayout>
  );
};

export default Article;
