exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-spotlight-tsx": () => import("./../../../src/pages/spotlight.tsx" /* webpackChunkName: "component---src-pages-spotlight-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/ArticleTemplate.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/BlogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-index-template-tsx": () => import("./../../../src/templates/IndexTemplate.tsx" /* webpackChunkName: "component---src-templates-index-template-tsx" */),
  "component---src-templates-institution-list-template-tsx": () => import("./../../../src/templates/InstitutionListTemplate.tsx" /* webpackChunkName: "component---src-templates-institution-list-template-tsx" */),
  "component---src-templates-landing-template-tsx": () => import("./../../../src/templates/LandingTemplate.tsx" /* webpackChunkName: "component---src-templates-landing-template-tsx" */),
  "component---src-templates-legal-info-template-tsx": () => import("./../../../src/templates/LegalInfoTemplate.tsx" /* webpackChunkName: "component---src-templates-legal-info-template-tsx" */),
  "component---src-templates-personnel-list-template-tsx": () => import("./../../../src/templates/PersonnelListTemplate.tsx" /* webpackChunkName: "component---src-templates-personnel-list-template-tsx" */),
  "component---src-templates-statement-template-tsx": () => import("./../../../src/templates/StatementTemplate.tsx" /* webpackChunkName: "component---src-templates-statement-template-tsx" */),
  "component---src-templates-story-template-tsx": () => import("./../../../src/templates/StoryTemplate.tsx" /* webpackChunkName: "component---src-templates-story-template-tsx" */)
}

