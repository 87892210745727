import { FC, useMemo } from "react";

import { Button, CmsImage, Html, ParallaxTile } from "@/components/elements";
import { ContentContainer } from "@/components/layout";
import { CmsImageData } from "@/types/cms-image-data";
import { getGatsbyImageData } from "@/utils/get-gatsby-image-data";

export interface LandingProps {
  pageTitle: string;
  bgImg: CmsImageData;
  color: string;
  bgColor: string;
  html: string;
  buttons: {
    label: string;
    url: string;
  }[];
  descr?: string;
}

const Landing: FC<LandingProps> = ({
  pageTitle,
  bgImg,
  color,
  bgColor,
  html,
  buttons,
}: LandingProps): JSX.Element => {
  const cmsImg = useMemo(() => {
    return getGatsbyImageData(bgImg);
  }, [bgImg]);

  return (
    <div className={"pt-[3.6em]"}>
      <ParallaxTile
        bgSlot={
          <CmsImage
            objectFit={"cover"}
            objectPosition={"center"}
            className={"h-full w-full object-cover object-top"}
            image={cmsImg}
            alt={pageTitle}
          />
        }
      />
      <div
        className={"flex justify-center text-lg py-10"}
        style={{ backgroundColor: bgColor, color }}
      >
        <ContentContainer>
          <article>
            <h1 className={"mt-0"}>{pageTitle}</h1>
            <Html className={"alternate-links"}>{html}</Html>
            <div className={"flex flex-wrap mt-10"}>
              {buttons?.map(({ label, url }) => (
                <div key={label} className={"mr-5 mt-2"}>
                  <Button as={"a"} href={url} isDark>
                    {label}
                  </Button>
                </div>
              ))}
            </div>
          </article>
        </ContentContainer>
      </div>
    </div>
  );
};

export default Landing;
