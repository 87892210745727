import { graphql, PageProps } from "gatsby";
import { FC } from "react";

import { PageMeta } from "@/components/elements";
import { MainLayout } from "@/components/layout";
import { MemberSpotlightProps, MemberSpotlight } from "@/features/member-spotlight";
import { NavbarLink } from "@/features/navbar";

interface QueryResult {
  navbar: {
    frontmatter: {
      navbarLinks: NavbarLink[];
    };
  };
  spotlight: {
    frontmatter: MemberSpotlightProps;
    html: string;
  };
}

const Spotlight: FC<PageProps<QueryResult>> = ({ data }: PageProps<QueryResult>): JSX.Element => {
  const { html } = data.spotlight;
  const { pageTitle, member, heading, descr, color, bgColor } = data.spotlight.frontmatter;
  const { navbarLinks } = data.navbar.frontmatter;

  return (
    <MainLayout navbarLinks={navbarLinks}>
      <PageMeta title={pageTitle} description={descr} />
      <MemberSpotlight
        pageTitle={pageTitle}
        member={member}
        heading={heading}
        color={color}
        bgColor={bgColor}
        html={html}
      />
    </MainLayout>
  );
};

export const query = graphql`
  query MemberSpotlightPageQuery {
    navbar: markdownRemark(fields: { collection: { eq: "navbar" } }) {
      frontmatter {
        navbarLinks {
          label
          url
        }
      }
    }
    spotlight: markdownRemark(fields: { collection: { eq: "spotlight" } }) {
      frontmatter {
        pageTitle
        descr
        member {
          name
          title
          location
          img {
            childImageSharp {
              gatsbyImageData(width: 200)
            }
          }
        }
        heading
        color
        bgColor
      }
      html
    }
  }
`;

export default Spotlight;
