import { FC, useMemo } from "react";

import { Html, PageTitleCard, PersonPicture } from "@/components/elements";
import { ContentContainer, ContentLayout } from "@/components/layout";
import { CmsImageData } from "@/types/cms-image-data";
import { getGatsbyImageData } from "@/utils/get-gatsby-image-data";

export interface MemberSpotlightProps {
  pageTitle: string;
  member: {
    img: CmsImageData;
    name: string;
    title: string;
    location: string;
  };
  heading: string;
  color: string;
  bgColor: string;
  html: string;
  descr?: string;
}

const MemberSpotlight: FC<MemberSpotlightProps> = ({
  pageTitle,
  member,
  heading,
  color,
  bgColor,
  html,
}: MemberSpotlightProps): JSX.Element => {
  const cmsImg = useMemo(() => getGatsbyImageData(member.img), [member.img]);

  return (
    <ContentLayout hideDivider>
      <article>
        <section className={"text-white py-10"} style={{ backgroundColor: bgColor }}>
          <ContentContainer>
            <PageTitleCard
              pageTitle={pageTitle}
              bgColor={"var(--colorLightBlue)"}
              textColor={"white"}
            />
            <div className={"flex justify-center sm:block"}>
              <div className={"bg-white w-fit rounded-2xl w-full sm:min-w-[30em] mt-10 p-5"}>
                <div className={"sm:flex"}>
                  <PersonPicture size={12} img={cmsImg} />
                  <div className={"flex justify-center items-center text-[#101E49] ml-0 sm:ml-10"}>
                    <div className={"text-center sm:text-left"}>
                      <div className={"text-lg font-bold"}>{member.name}</div>
                      <div>{member.title}</div>
                      <div>{member.location}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h2 className={"h1"}>{heading}</h2>
            <Html className={"alternate-links"} style={{ color }}>
              {html}
            </Html>
          </ContentContainer>
        </section>
      </article>
    </ContentLayout>
  );
};

export default MemberSpotlight;
