import { FC } from "react";

import { Button, Html } from "@/components/elements";

export interface QuoteHeroProps {
  label: string;
  textMd: string;
  color: string;
  bgColor: string;
  url: string;
}

const QuoteHero: FC<QuoteHeroProps> = ({
  label,
  textMd,
  bgColor,
  color,
  url,
}: QuoteHeroProps): JSX.Element => {
  return (
    <div className={"h-full flex justify-center items-center"} style={{ backgroundColor: bgColor }}>
      <div
        className={"w-full sm:w-3/4 px-4 sm:px-0 2xl:w-8/12 h-fit text-center"}
        style={{ color }}
      >
        <div className={"tracking-[0.25em] mb-10 text-[1.1em] sm:text-[1.35em] font-bold"}>
          {label}:
        </div>
        <Html noStyle className={"sm:leading-[1.8em] alternate-links"}>
          {textMd}
        </Html>
        <div className={"mt-12 font-bold text-xl"}>
          <Button isSmall as={"a"} href={url}>
            Read Statement
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QuoteHero;
