import { FC } from "react";

import { Button } from "@/components/elements";

interface Props {
  hero: string;
  heading: string;
  text: string;
}

const ErrorLayout: FC<Props> = ({ hero, heading, text }: Props): JSX.Element => {
  return (
    <div
      className={"flex justify-center items-center w-screen h-screen bg-lightBlue text-white"}
      style={{
        height: "calc(100vh - 150px",
      }}
    >
      <div className={"text-center"}>
        <div className={"text-[60px] font-bold"}>{hero}</div>
        <h1 className={"m-0"}>{heading}</h1>
        <p className={"text-lg my-5"}>{text}</p>
        <div className={"flex justify-center"}>
          <div className={"w-[200px] h-[50px]"}>
            <Button as={"a"} title={"Go to homepage"} href={"/"} isDark>
              Go to homepage
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorLayout;
