import { PreviewTemplateComponentProps } from "netlify-cms-core";
import { FC, useMemo } from "react";

import { IndexProps, Index } from "@/features/index";
import { deepParseHtml } from "@/utils/deep-parse-html";

const IndexPreview: FC<PreviewTemplateComponentProps> = ({
  getAsset,
  entry,
}: PreviewTemplateComponentProps): JSX.Element => {
  const props = useMemo(() => {
    const props: IndexProps = deepParseHtml(entry.get("data").toJS());

    props.bannerImg = getAsset(props.bannerImg as string).toString();
    props.pageTitle = props.pageTitle || "Enter Page Title";
    props.sections = props.sections ?? [];
    props.sections = props.sections.map((section) => {
      section.heading = section.heading ?? "Enter Section Title";
      section.createdDate = new Date(section?.createdDate) ?? new Date();
      section.img = getAsset(section?.img as string).toString();
      section.textMd = section.textMd ?? "Enter text...";
      section.url = section.url ?? "/#";
      return section;
    });

    return props;
  }, [entry, getAsset]);

  return <Index {...props} />;
};

export default IndexPreview;
