import { motion, Variants } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { CgClose } from "react-icons/cg";
import { FiMenu } from "react-icons/fi";
import { useMedia } from "react-use";

import { CmsFocusTrap, CmsLink } from "@/components/elements";
import { zIndex } from "@/types/constants";

import { NavbarLink } from "../types";

import DropdownMenu from "./DropdownMenu";
import FullMenu from "./MobileMenu";
import SearchBox from "./SearchBox";

interface Props {
  isVisible: boolean;
  navbarLinks: NavbarLink[];
  isTransparent?: boolean;
  isPreview?: boolean;
}

const Navbar: FC<Props> = ({
  navbarLinks,
  isVisible,
  isTransparent = false,
}: Props): JSX.Element => {
  const isMobile = useMedia("(max-width: 768px");
  const refFullMenu = useRef<HTMLDivElement>(null);
  const refMenuToggle = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenuOpen = useCallback(() => {
    setIsMenuOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (!isMobile && isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [isMenuOpen, isMobile]);

  useEffect(() => {
    if (!isVisible && isDropdownOpen) {
      setIsDropdownOpen(false);
    }
  }, [isDropdownOpen, isVisible]);

  return (
    <>
      <motion.div
        className={"w-full fixed pb-1"}
        style={{
          backgroundColor: isTransparent ? "transparent" : "var(--colorBlue)",
          pointerEvents: isVisible ? "auto" : "none",
          zIndex: zIndex.dropdown,
        }}
        variants={showHideVariants}
        initial={isVisible ? "visible" : "hidden"}
        animate={isVisible ? "visible" : "hidden"}
      >
        <div className={"container mx-auto"}>
          <div className={"grid grid-cols-2"}>
            <div
              className={
                "w-fit flex justify-center items-center rounded-b-lg h-[3.5em] ml-2 lg:ml-0"
              }
            >
              <CmsLink title={"Go to home page"} href={"/"}>
                <StaticImage
                  width={180}
                  height={33}
                  src={"../../../assets/images/nadohe-logo.png"}
                  alt={"Nadohe Logo"}
                />
              </CmsLink>
            </div>
            <div className={"hidden md:flex justify-end"}>
              <div className={"mr-2"}>
                <div
                  className={
                    "flex w-fit justify-center items-center rounded-b-lg bg-white h-[2.5em] p-3.5"
                  }
                  style={{
                    boxShadow: isTransparent ? "rgba(0, 0, 0, 0.5) 0px 0px 10px 0px" : "none",
                  }}
                >
                  <SearchBox onFocus={() => setIsDropdownOpen(false)} />
                </div>
              </div>
              <div
                className={
                  "flex w-fit justify-center items-center rounded-b-lg bg-white h-[2.5em] p-3.5"
                }
                style={{
                  boxShadow: isTransparent ? "rgba(0, 0, 0, 0.5) 0px 0px 10px 0px" : "none",
                }}
              >
                <DropdownMenu
                  isOpen={isDropdownOpen}
                  setIsOpen={setIsDropdownOpen}
                  links={navbarLinks}
                />
              </div>
            </div>
            <CmsFocusTrap
              active={isMenuOpen}
              containerElements={[refMenuToggle.current!, refFullMenu.current!]}
            >
              <div ref={refMenuToggle} className={"flex md:hidden justify-end mr-2"}>
                {isMenuOpen ? (
                  <button title={"Close navigation menu"} onClick={toggleMenuOpen}>
                    <CgClose size={"2em"} color={"white"} />
                  </button>
                ) : (
                  <button title={"Open navigation menu"} onClick={toggleMenuOpen}>
                    <FiMenu size={"2em"} color={"white"} />
                  </button>
                )}
              </div>
            </CmsFocusTrap>
          </div>
        </div>
      </motion.div>
      <FullMenu ref={refFullMenu} isOpen={isMenuOpen} navbarLinks={navbarLinks} />
    </>
  );
};

const showHideVariants: Variants = {
  visible: {
    opacity: 1,
    translateY: "0%",
    transition: {
      ease: "linear",
      duration: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    translateY: "-100%",
    transition: {
      ease: "linear",
      duration: 0.35,
      delay: 0.2,
    },
  },
};

export default Navbar;
