import { FC } from "react";

interface Props {
  pageTitle: string;
  bgColor: string;
  textColor: string;
}

const PageTitleCard: FC<Props> = ({ pageTitle, textColor, bgColor }: Props): JSX.Element => {
  return (
    <div
      className={"opacity-90 inline-block min-w-[17.6em] p-[0.8em] mt-5"}
      style={{
        color: textColor,
        backgroundColor: bgColor,
        boxShadow: "rgba(66, 124, 191, 0.1) 7.07px 7.07px 10px 0px",
      }}
    >
      <h1 className={"m-0 uppercase tracking-[0.3em] text-lg"}>{pageTitle}</h1>
    </div>
  );
};

export default PageTitleCard;
