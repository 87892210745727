import { FC } from "react";

import { PageTitleCard, PersonPicture } from "@/components/elements";
import { ContentContainer, ContentLayout } from "@/components/layout";
import { CmsImageData } from "@/types/cms-image-data";

export interface PersonnelListProps {
  pageTitle: string;
  sections: {
    heading: string;
    personnelList: {
      img: CmsImageData;
      name: string;
      title: string;
      contact?: string;
    }[];
  }[];
  descr?: string;
}

const PersonnelList: FC<PersonnelListProps> = ({
  pageTitle,
  sections,
}: PersonnelListProps): JSX.Element => {
  return (
    <ContentLayout>
      <ContentContainer>
        <div className={"mt-5"}>
          <PageTitleCard pageTitle={pageTitle} bgColor={"var(--colorBlue)"} textColor={"white"} />
        </div>
        {sections.map(({ heading, personnelList }) => (
          <section key={heading}>
            <h2 className={"h1"}>{heading}</h2>
            <div className={"grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-y-[3em]"}>
              {personnelList.map(({ name, title, contact, img }) => (
                <div key={name} className={"flex justify-center lg:block"}>
                  <div className={"w-full sm:flex sm:items-center"}>
                    <div className={"w-full flex justify-center sm:w-[12em]"}>
                      <PersonPicture size={12} img={img} borderColor={"white"} />
                    </div>
                    <div className={"ml-0 sm:ml-10 text-center sm:text-left"}>
                      <div className={"text-lg font-bold"}>{name}</div>
                      <div className={"my-1"}>{title}</div>
                      <div>{contact}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        ))}
      </ContentContainer>
    </ContentLayout>
  );
};

export default PersonnelList;
