import { graphql, PageProps } from "gatsby";
import * as React from "react";

import { LinkHeroProps, PageMeta } from "@/components/elements";
import { MainLayout } from "@/components/layout";
import {
  Home,
  LandingTileProps,
  LinkCardProps,
  PresidentsMessageProps,
  QuoteHeroProps,
} from "@/features/home";
import { NavbarLink } from "@/features/navbar";

interface QueryResult {
  navbar: {
    frontmatter: {
      navbarLinks: NavbarLink[];
    };
  };
  homePage: {
    frontmatter: {
      descr: string;
      landingTiles: LandingTileProps[];
      linkHeroes: LinkHeroProps[];
      linkCards: LinkCardProps[];
      presidentsMessage: PresidentsMessageProps;
      quoteHeroes: QuoteHeroProps[];
    };
  };
}

const HomePage = (props: PageProps<QueryResult>) => {
  const { navbarLinks } = props.data.navbar.frontmatter;
  const { landingTiles, linkCards, presidentsMessage, quoteHeroes, linkHeroes, descr } =
    props.data.homePage.frontmatter;

  return (
    <MainLayout navbarLinks={navbarLinks} clearNav>
      <PageMeta title={"Home"} description={descr} />
      <Home
        linkHeroes={linkHeroes}
        landingTiles={landingTiles}
        linkCards={linkCards}
        presidentsMessage={{ ...presidentsMessage, date: new Date(presidentsMessage.date) }}
        quoteHeroes={quoteHeroes}
      />
    </MainLayout>
  );
};

export const query = graphql`
  query HomePageQuery {
    navbar: markdownRemark(fields: { collection: { eq: "navbar" } }) {
      frontmatter {
        navbarLinks {
          label
          url
        }
      }
    }
    homePage: markdownRemark(fields: { collection: { eq: "home" } }) {
      frontmatter {
        descr
        landingTiles {
          isTinted
          bgImg {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
          heading
          subHeading
          textMd
          linkLabel
          url
          logoImg {
            childImageSharp {
              gatsbyImageData(width: 115)
            }
          }
        }
        linkHeroes {
          label
          url
          color
          bgColor
        }
        linkCards {
          label
          textMd
          textAlignment
          url
          headingColor
          color
          bgColor
          bgImg {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
        }
        presidentsMessage {
          date
          img {
            childImageSharp {
              gatsbyImageData(width: 150, quality: 100)
            }
          }
          imgUrl
          textMd
          url
        }
        quoteHeroes {
          label
          textMd
          url
          color
          bgColor
        }
      }
    }
  }
`;

export default HomePage;
