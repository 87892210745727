import { graphql, PageProps } from "gatsby";
import { FC } from "react";

import { PageMeta } from "@/components/elements";
import { MainLayout } from "@/components/layout";
import { Article, ArticleProps } from "@/features/article";
import { NavbarLink } from "@/features/navbar";

interface QueryResult {
  navbar: {
    frontmatter: {
      navbarLinks: NavbarLink[];
    };
  };
  article: {
    frontmatter: ArticleProps;
  };
}

const ArticleTemplate: FC<PageProps<QueryResult>> = ({
  data,
}: PageProps<QueryResult>): JSX.Element => {
  const { pageTitle, headlineCard, mainSection, subSections, descr } = data.article.frontmatter;
  const { navbarLinks } = data.navbar.frontmatter;

  return (
    <MainLayout navbarLinks={navbarLinks}>
      <PageMeta isTemplate title={pageTitle} category={"Articles"} description={descr} />
      <Article
        pageTitle={pageTitle}
        headlineCard={headlineCard}
        mainSection={mainSection}
        subSections={subSections ?? []}
      />
    </MainLayout>
  );
};

export const query = graphql`
  query ArticlePageQuery($id: String) {
    navbar: markdownRemark(fields: { collection: { eq: "navbar" } }) {
      frontmatter {
        navbarLinks {
          label
          url
        }
      }
    }
    article: markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        descr
        headlineCard {
          headline
          textMd
          bgImg {
            childImageSharp {
              gatsbyImageData(quality: 100, height: 410)
            }
          }
        }
        mainSection {
          header
          textMd
        }
        subSections {
          header
          textMd
          image {
            childImageSharp {
              gatsbyImageData(width: 500)
            }
          }
        }
      }
    }
  }
`;

export default ArticleTemplate;
