import { getImage, IGatsbyImageData } from "gatsby-plugin-image";

import { CmsImageData } from "@/types/cms-image-data";

export function getGatsbyImageData(cmsImage: CmsImageData): IGatsbyImageData | string | undefined {
  if (!cmsImage) {
    return undefined;
  }

  if (typeof cmsImage === "string") {
    return cmsImage;
  }

  return getImage(cmsImage);
}
