import { PreviewTemplateComponentProps } from "netlify-cms-core";
import { FC, useMemo } from "react";

import { LegalInfo, LegalInfoProps } from "@/features/legal-info";
import { deepParseHtml } from "@/utils/deep-parse-html";

const LegalInfoPreview: FC<PreviewTemplateComponentProps> = ({
  entry,
}: PreviewTemplateComponentProps): JSX.Element => {
  const props = useMemo(() => {
    const props: LegalInfoProps = deepParseHtml(entry.get("data").toJS());

    props.pageTitle = props.pageTitle ?? "Enter page title";
    props.contentMd = props.contentMd ?? "Enter page body";

    return props;
  }, [entry]);

  return <LegalInfo {...props} />;
};

export default LegalInfoPreview;
