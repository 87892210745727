import { FC } from "react";

import { Button, CmsLink, Html, PersonPicture } from "@/components/elements";
import { CmsImageData } from "@/types/cms-image-data";
import { monthNames } from "@/types/constants";

export interface PresidentsMessageProps {
  date: Date;
  img: CmsImageData;
  textMd: string;
  imgUrl: string;
  url: string;
}

const PresidentsMessage: FC<PresidentsMessageProps> = ({
  date,
  img,
  imgUrl,
  textMd,
  url,
}: PresidentsMessageProps): JSX.Element => {
  return (
    <div className={"w-[38em] px-7"}>
      <div className={"block md:flex"}>
        <div className={"mr-5"}>
          <CmsLink href={imgUrl}>
            <PersonPicture size={10} img={img} />
          </CmsLink>
        </div>
        <div>
          <div className={"uppercase text-xl font-bold tracking-[0.5em] text-lightBlue"}>
            {monthNames[date.getMonth()]}
          </div>
          <div className={"text-3xl sm:text-4xl lg:text-3xl xl:text-4xl font-bold my-3"}>
            Message from the President
          </div>
        </div>
      </div>
      <Html noStyle className={"py-7 text-lg"}>
        {textMd}
      </Html>
      <div>
        <Button
          title={"Read More"}
          as={"a"}
          href={url}
          label={"Read a message from the NADOHE president"}
        >
          Read More
        </Button>
      </div>
    </div>
  );
};

export default PresidentsMessage;
