const config = {
  ENV: process.env.NODE_ENV!,
  ALGOLIA_APP_ID: process.env.GATSBY_ALGOLIA_APP_ID!,
  ALGOLIA_API_KEY: process.env.GATSBY_ALGOLIA_SEARCH_KEY!,
  GTAG: process.env.GATSBY_G_TAG!,
};

Object.entries(config).forEach(([key, val]) => {
  if (val === undefined) {
    throw new Error(`Env var ${key} is undefined.`);
  }
});

export default config;
