import { FC } from "react";

import { Carousel } from "@/components/elements";
import { LandingTileProps, LinkCardProps, QuoteHeroProps } from "@/features/home";

import LinkHero, { LinkHeroProps } from "../../../components/elements/LinkHero";
import * as Styles from "../home.module.css";

import LandingTile from "./LandingTile";
import LinkCard from "./LinkCard";
import PresidentsMessage, { PresidentsMessageProps } from "./PresidentsMessage";
import QuoteHero from "./QuoteHero";

export interface HomeProps {
  linkHeroes: LinkHeroProps[];
  landingTiles: LandingTileProps[];
  linkCards: LinkCardProps[];
  presidentsMessage: PresidentsMessageProps;
  quoteHeroes: QuoteHeroProps[];
}

const Main: FC<HomeProps> = ({
  linkHeroes,
  landingTiles,
  linkCards,
  presidentsMessage,
  quoteHeroes,
}: HomeProps): JSX.Element => {
  return (
    <div>
      <div className={"relative"}>
        <div
          className={"absolute w-full top-[5.5em] hidden md:block"}
          style={{ zIndex: landingTiles.length + 1 }}
        >
          <div className={"container mx-auto"}>
            <div className={"flex justify-between"}>
              {linkHeroes.map((link) => (
                <div
                  key={link.label}
                  className={"py-1.5 pr-2 last:pr-0"}
                  style={{
                    width: `${100 / linkHeroes.length}%`,
                  }}
                >
                  <LinkHero {...link} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className={"h-[55em] portrait:max-h-screen landscape:max-h-[55em] overflow-hidden"}
          style={{ boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 10px 0px" }}
        >
          <Carousel>
            {landingTiles.map((tile) => (
              <LandingTile key={tile.heading} {...tile} />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={"flex justify-center"}>
        <div className={Styles.cardGrid}>
          {linkCards.map((card) => (
            <div
              key={card.label}
              className={Styles.card}
              style={{ boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px 0px" }}
            >
              <LinkCard {...card} />
            </div>
          ))}
        </div>
      </div>
      <div className={"grid grid-cols-1 lg:grid-cols-2"}>
        <div className={"h-[55em] overflow-hidden"}>
          <div className={"bg-white h-full flex items-center justify-center pb-14"}>
            <PresidentsMessage {...presidentsMessage} />
          </div>
        </div>
        <div className={"h-[55em] max-h-screen overflow-hidden"}>
          <div className={"h-full"}>
            <Carousel hasMiniControls={true}>
              {quoteHeroes.map((quote) => (
                <QuoteHero key={quote.label} {...quote} />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
