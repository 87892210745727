import { motion, useTransform, useViewportScroll } from "framer-motion";
import { FC, ReactNode } from "react";

interface Props {
  bgSlot: ReactNode;
  fgSlot?: ReactNode;
  height?: string;
}

const ParallaxTile: FC<Props> = ({ bgSlot, fgSlot, height = "25.6em" }: Props): JSX.Element => {
  const { scrollY } = useViewportScroll();
  const parallaxTransform = useTransform(scrollY, [0, 1000], [0, 300]);

  return (
    <div className={"grid w-full overflow-hidden"} style={{ height }}>
      <motion.div
        className={"bg-slate-200 w-full relative"}
        style={{
          gridArea: "1/1",
          translateY: parallaxTransform,
          willChange: "transform",
        }}
      >
        {bgSlot}
      </motion.div>
      {fgSlot && (
        <div
          className={"grid relative w-full place-items-center"}
          style={{
            height,
            gridArea: "1/1",
          }}
        >
          {fgSlot}
        </div>
      )}
    </div>
  );
};

export default ParallaxTile;
