import "./src/global.css";

import { isBrowser, KEY_USERWAY_CONFIG, userwayConfig } from "./src/types/constants";

export function onClientEntry() {
  if (isBrowser) {
    window[KEY_USERWAY_CONFIG as keyof Window] = userwayConfig;

    const userWayWidget = document.createElement("script");
    userWayWidget.setAttribute("type", "text/javascript");
    userWayWidget.setAttribute("src", "https://cdn.userway.org/widget.js");

    document.documentElement.setAttribute("lang", "en");
    document.head.appendChild(userWayWidget);
  }
}
