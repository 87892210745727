import { FC } from "react";

import { CmsLink } from "@/components/elements";
import { INFO_EMAIL } from "@/types/constants";

const ContactDetails: FC = (): JSX.Element => {
  return (
    <div className={"w-[30em]"}>
      <div className={"font-bold py-9"}>Leading Higher Education Towards Inclusive Excellence</div>
      <div className={"text-sm"}>
        {[
          <>
            © {new Date().getFullYear()} National Association of Diversity Officers in Higher
            Education
          </>,
          <>1050 Connecticut Avenue NW, Suite 500, Washington, DC 20036</>,
          <>
            phone: 800-793-7025 | fax: 800-837-7321 |&nbsp;
            <CmsLink title={"Email us"} href={`mailto:${INFO_EMAIL}`}>
              {INFO_EMAIL}
            </CmsLink>
          </>,
          <>
            <CmsLink title={"View PrivacyPolicy"} href={"/legal/privacy-policy"}>
              NADOHE Privacy Policy
            </CmsLink>
            &nbsp;| <CmsLink href={"/legal/terms-of-use"}>NADOHE Terms of Use</CmsLink>
          </>,
        ].map((line, index) => (
          <div key={index} className={"py-1.5"}>
            {line}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactDetails;
