import { GatsbyImage, GatsbyImageProps, IGatsbyImageData } from "gatsby-plugin-image";
import { FC } from "react";

export type CmsImageProps = Omit<GatsbyImageProps, "image"> & {
  image: IGatsbyImageData | string | undefined;
};

const CmsImage: FC<CmsImageProps> = (props: CmsImageProps): JSX.Element => {
  if (!props.image || typeof props.image === "string") {
    return (
      <img style={props.style} className={props.className} src={props.image} alt={props.alt} />
    );
  } else {
    const gatsbyImgProps = { ...props, image: props.image as IGatsbyImageData };

    return <GatsbyImage {...gatsbyImgProps} />;
  }
};

export default CmsImage;
