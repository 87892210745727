import remark from "remark";
import remarkHtml from "remark-html";

// NADOHE agreed to disable markdown sanitization in order to embed
// YouTube videos etc.
const parser = remark().use(remarkHtml, { sanitize: false });

export function deepParseHtml<T>(obj: T): T {
  Object.entries(obj).forEach((entry) => {
    const [key, value] = entry;

    if (Array.isArray(value)) {
      Object.values(value).forEach(deepParseHtml);
    } else if (value !== null && typeof value === "object") {
      deepParseHtml(value as Record<string, unknown>);
    }

    if (key.match(/.*Md$/) || key === "body") {
      if (typeof value !== "string") {
        throw new Error(
          `${key} in markdown node doesn't have a string value. Cannot parse markdown.`
        );
      }

      (obj as Record<string, unknown>)[key] = parser.processSync(value as string).contents;
    }
  });

  return obj;
}
