import { graphql, PageProps } from "gatsby";
import { FC } from "react";

import { PageMeta } from "@/components/elements";
import { MainLayout } from "@/components/layout";
import { Blog, BlogProps } from "@/features/blog";
import { NavbarLink } from "@/features/navbar";

interface QueryResult {
  navbar: {
    frontmatter: {
      navbarLinks: NavbarLink[];
    };
  };
  blog: {
    frontmatter: BlogProps;
  };
}

const BlogTemplate: FC<PageProps<QueryResult>> = ({
  data,
}: PageProps<QueryResult>): JSX.Element => {
  const { pageTitle, entries, descr } = data.blog.frontmatter;
  const { navbarLinks } = data.navbar.frontmatter;

  return (
    <MainLayout navbarLinks={navbarLinks}>
      <PageMeta isTemplate title={pageTitle} category={"Blogs"} description={descr} />
      <Blog
        pageTitle={pageTitle}
        entries={entries.map((entry) => ({ ...entry, created: new Date(entry.created) }))}
      />
    </MainLayout>
  );
};

export const query = graphql`
  query BlogPageQuery($id: String) {
    navbar: markdownRemark(fields: { collection: { eq: "navbar" } }) {
      frontmatter {
        navbarLinks {
          label
          url
        }
      }
    }
    blog: markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        descr
        entries {
          created
          img {
            childImageSharp {
              gatsbyImageData(height: 500)
            }
          }
          url
          heading
          headingColor
          textColor
          bgColor
          textMd
        }
      }
    }
  }
`;

export default BlogTemplate;
