import { motion, useAnimation, Variants } from "framer-motion";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef } from "react";
import { CgClose } from "react-icons/cg";
import { FiMenu } from "react-icons/fi";
import { useClickAway } from "react-use";

import { CmsLink } from "@/components/elements";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  links: {
    label: string;
    url: string;
  }[];
}

const DropdownMenu: FC<Props> = ({ links, isOpen, setIsOpen }: Props): JSX.Element => {
  const animate = useAnimation();
  const refDropdown = useRef<HTMLDivElement>(null);
  const refOpen = useRef<HTMLButtonElement>(null);
  const refFirstItem = useRef<HTMLAnchorElement>(null);
  const toggleMenuOpen = useCallback(() => setIsOpen((prevState) => !prevState), [setIsOpen]);

  const slideIn = useCallback(async () => {
    await animate.start(["visible", "slideIn"]).then(() => {
      refFirstItem.current?.focus();
    });
  }, [animate]);

  const slideOut = useCallback(async () => {
    await animate.start(["slideOut", "hidden"]);
  }, [animate]);

  const close = useCallback(
    (event: any) => {
      event.keyCode === 9 && !event.shiftKey && toggleMenuOpen();
    },
    [toggleMenuOpen]
  );

  useClickAway(refDropdown, () => setIsOpen(false));

  useEffect(() => {
    if (isOpen) {
      slideIn();
    } else {
      slideOut();
    }
  }, [isOpen, slideIn, slideOut]);

  return (
    <div className={"relative"} ref={refDropdown}>
      <div className={"flex items-center"}>
        {isOpen ? (
          <button ref={refOpen} title={"Close navigation menu"} onClick={toggleMenuOpen}>
            <CgClose size={"1.4em"} />
          </button>
        ) : (
          <button title={"Open navigation menu"} onClick={toggleMenuOpen}>
            <FiMenu size={"1.4em"} />
          </button>
        )}
      </div>
      <div
        className={"absolute top-[35px] right-[-15px] overflow-hidden"}
        style={{ pointerEvents: isOpen ? "auto" : "none" }}
      >
        <motion.div
          className={"bg-white w-[12.5em] rounded-lg overflow-hidden"}
          variants={showHideVariants}
          initial={["slideOut", "hidden"]}
          animate={animate}
          aria-hidden={!isOpen}
        >
          <nav>
            <ul>
              {links.map(({ label, url }, index) => (
                <li
                  key={label}
                  className={
                    "group bg-white cursor-pointer text-center transition-colors duration-400 hover:bg-lightBlue"
                  }
                >
                  <CmsLink
                    ref={index === 0 ? refFirstItem : undefined}
                    className={"block w-full h-full group-hover:text-white p-2"}
                    title={label}
                    href={url}
                    onKeyDown={index === links.length - 1 ? close : undefined}
                  >
                    {label}
                  </CmsLink>
                </li>
              ))}
            </ul>
          </nav>
        </motion.div>
      </div>
    </div>
  );
};

const showHideVariants: Variants = {
  visible: {
    visibility: "visible",
    transition: {
      delay: 0,
    },
  },
  slideOut: {
    translateY: "-100%",
    transition: {
      ease: "easeOut",
      duration: 0.3,
    },
  },
  slideIn: {
    translateY: "0%",
    transition: {
      ease: "easeIn",
      duration: 0.3,
    },
  },
  hidden: {
    visibility: "hidden",
    transition: {
      delay: 0.3,
    },
  },
};

export default DropdownMenu;
