import { PreviewTemplateComponentProps } from "netlify-cms-core";
import { FC, useMemo } from "react";

import { deepParseHtml } from "@/utils/deep-parse-html";

import MemberSpotlight, { MemberSpotlightProps } from "./Main";

const MemberSpotlightPreview: FC<PreviewTemplateComponentProps> = ({
  getAsset,
  entry,
}: PreviewTemplateComponentProps): JSX.Element => {
  const props = useMemo(() => {
    const props: MemberSpotlightProps = deepParseHtml(entry.get("data").toJS());

    props.pageTitle = props.pageTitle || "Enter Page Title";
    props.member = props.member ?? {};
    props.member.name = props.member.name || "Enter member name";

    props.member.img = getAsset(props.member.img as string).toString();
    props.html = props.html ?? (props as any).body ?? "Enter page body";

    return props;
  }, [entry, getAsset]);

  return <MemberSpotlight {...props} />;
};

export default MemberSpotlightPreview;
