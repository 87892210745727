import { motion, useAnimation, Variants } from "framer-motion";
import { navigate } from "gatsby";
import { FC, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useClickAway } from "react-use";

import { Button } from "@/components/elements";

interface Props {
  onFocus: () => void;
}

const SearchBox: FC<Props> = ({ onFocus }: Props): JSX.Element => {
  const animate = useAnimation();
  const refContainer = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerms, setSearchTerms] = useState("");

  const toggleSearchOpen = useCallback(() => setIsOpen((prevState) => !prevState), []);

  const slideIn = useCallback(async () => {
    await animate.start(["visible", "slideIn"]).then(() => {
      refInput.current?.focus();
    });
  }, [animate]);

  const slideOut = useCallback(async () => {
    await animate.start(["slideOut", "hidden"]);
  }, [animate]);

  const submitSearch = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      setSearchTerms("");

      navigate("/search", {
        state: {
          term: searchTerms,
        },
      });
    },
    [searchTerms]
  );

  const acceptSearchTerm = useCallback(
    (event: FormEvent<HTMLInputElement>) => setSearchTerms(event.currentTarget.value),
    []
  );

  const close = useCallback((event: any) => {
    event.keyCode === 9 && !event.shiftKey && setIsOpen(false);
  }, []);

  useClickAway(refContainer, () => setIsOpen(false));

  useEffect(() => {
    if (isOpen) {
      slideIn();
    } else {
      slideOut();
    }
  }, [isOpen, slideIn, slideOut]);

  return (
    <div ref={refContainer} className={"flex justify-center items-center"}>
      <button
        title={`${isOpen ? "Close" : "Open"} site search`}
        onClick={toggleSearchOpen}
        onFocus={onFocus}
      >
        <FiSearch size={"1.4em"} />
      </button>
      <motion.form
        onSubmit={submitSearch}
        className={"flex overflow-hidden"}
        variants={searchVariants}
        initial={["slideOut", "hidden"]}
        animate={animate}
        aria-hidden={!isOpen}
      >
        <input
          ref={refInput}
          className={"w-full border-neutral-300 px-1 border rounded"}
          placeholder={"Enter search terms"}
          onChange={acceptSearchTerm}
          role={"searchbox"}
        />
        <div className={"ml-1.5 w-2/6"}>
          <Button
            title={"Site search"}
            label={"Search site for provided terms"}
            type={"submit"}
            onKeyDown={close}
          >
            Search
          </Button>
        </div>
      </motion.form>
    </div>
  );
};

const searchVariants: Variants = {
  visible: {
    visibility: "visible",
    transition: {
      delay: 0,
    },
  },
  slideIn: {
    width: "20em",
    marginLeft: "0.5em",
    transition: {
      ease: "easeIn",
      duration: 0.3,
    },
  },
  slideOut: {
    width: "0em",
    marginLeft: "0em",
    transition: {
      ease: "easeOut",
      duration: 0.3,
    },
  },
  hidden: {
    visibility: "hidden",
    transition: {
      delay: 0.3,
    },
  },
};

export default SearchBox;
