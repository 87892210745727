import { PreviewTemplateComponentProps } from "netlify-cms-core";
import { FC, useMemo } from "react";

import { Navbar, NavbarLink } from "@/features/navbar";

const NavbarPreview: FC<PreviewTemplateComponentProps> = ({
  entry,
}: PreviewTemplateComponentProps): JSX.Element => {
  const props = useMemo(() => {
    const props: { navbarLinks: NavbarLink[] } = entry.get("data").toJS();

    props.navbarLinks = props.navbarLinks.filter((link) => !!link.label);

    return props;
  }, [entry]);

  return (
    <div className={"bg-gray w-screen h-screen pt-[7em]"}>
      <Navbar isPreview isVisible={true} {...props} />
    </div>
  );
};

export default NavbarPreview;
