import FocusTrap from "focus-trap-react";
import { FC } from "react";

import { isBrowser, KEY_IS_PREVIEW } from "@/types/constants";

function isPreview(): boolean {
  if (!isBrowser) {
    return false;
  }

  return window[KEY_IS_PREVIEW as keyof Window];
}

const CmsFocusTrap: FC<FocusTrap.Props> = (props: FocusTrap.Props): JSX.Element => {
  if (isPreview()) {
    return <>{props.children}</>;
  } else {
    return <FocusTrap {...props} />;
  }
};

export default CmsFocusTrap;
