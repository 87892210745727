import { graphql, PageProps } from "gatsby";
import { FC } from "react";

import { PageMeta } from "@/components/elements";
import { MainLayout } from "@/components/layout";
import { InstitutionList, InstitutionListProps } from "@/features/institution-list";
import { NavbarLink } from "@/features/navbar";

interface QueryResult {
  navbar: {
    frontmatter: {
      navbarLinks: NavbarLink[];
    };
  };
  institutionList: {
    frontmatter: InstitutionListProps;
  };
}

const InstitutionListTemplate: FC<PageProps<QueryResult>> = ({
  data,
}: PageProps<QueryResult>): JSX.Element => {
  const { pageTitle, categories, descr } = data.institutionList.frontmatter;
  const { navbarLinks } = data.navbar.frontmatter;

  return (
    <MainLayout navbarLinks={navbarLinks}>
      <PageMeta isTemplate title={pageTitle} description={descr} />
      <InstitutionList pageTitle={pageTitle} categories={categories} />
    </MainLayout>
  );
};

export const query = graphql`
  query InstitutionListPageQuery($id: String) {
    navbar: markdownRemark(fields: { collection: { eq: "navbar" } }) {
      frontmatter {
        navbarLinks {
          label
          url
        }
      }
    }
    institutionList: markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        descr
        categories {
          heading
          institutionList {
            img {
              childImageSharp {
                gatsbyImageData(height: 500)
              }
            }
            name
            descriptionMd
          }
        }
      }
    }
  }
`;

export default InstitutionListTemplate;
