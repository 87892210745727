import { StaticImage } from "gatsby-plugin-image";
import { FC } from "react";

import { CmsLink } from "@/components/elements";

const SocialLinks: FC = (): JSX.Element => {
  return (
    <div className={"w-[30em]"}>
      <div className={"font-bold py-9 lg:text-right"}>Join Us On Social Media</div>
      <div className={"flex lg:justify-end"}>
        <ul className={"flex"}>
          <li>
            <CmsLink href={"https://www.facebook.com/NADOHE.org"}>
              <StaticImage
                width={40}
                height={40}
                className={"mr-3 lg:mr-0 lg:ml-3"}
                src={"../../../assets/images/icon-fb.png"}
                alt={"Link to Twitter"}
              />
            </CmsLink>
          </li>
          <li>
            <CmsLink href={"https://twitter.com/nadohe_?lang=en"}>
              <StaticImage
                width={40}
                height={40}
                className={"mr-3 lg:mr-0 lg:ml-3"}
                src={"../../../assets/images/icon-tw.png"}
                alt={"Link to Facebook"}
              />
            </CmsLink>
          </li>
          <li>
            <CmsLink href={"https://www.linkedin.com/company/nadohe"}>
              <StaticImage
                width={40}
                height={40}
                className={"mr-3 lg:mr-0 lg:ml-3"}
                src={"../../../assets/images/icon-li.png"}
                alt={"Link to LinkedIn"}
              />
            </CmsLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SocialLinks;
