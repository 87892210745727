import { FC, ReactNode } from "react";

import { SkipToMain } from "@/components/elements";
import { Footer } from "@/features/footer";
import { Navbar, NavbarLink } from "@/features/navbar";
import { useOnFirstRender, useScrollWaypoint } from "@/hooks";
import { ID_MAIN } from "@/types/constants";

interface Props {
  children: ReactNode;
  navbarLinks: NavbarLink[];
  clearNav?: boolean;
}

const MainLayout: FC<Props> = ({ navbarLinks, children, clearNav = false }: Props): JSX.Element => {
  const { isPassedYPos: shouldHideNav } = useScrollWaypoint(100);

  useOnFirstRender(() => window.scrollTo(0, 0));

  return (
    <>
      <SkipToMain targetId={ID_MAIN} />
      <header>
        <Navbar isVisible={!shouldHideNav} navbarLinks={navbarLinks} isTransparent={clearNav} />
      </header>
      <main tabIndex={-1} id={ID_MAIN}>
        {children}
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default MainLayout;
