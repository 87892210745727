import { PreviewTemplateComponentProps } from "netlify-cms-core";
import { FC, useMemo } from "react";

import { Home, HomeProps } from "@/features/home";
import { deepParseHtml } from "@/utils/deep-parse-html";

const HomePreview: FC<PreviewTemplateComponentProps> = ({
  getAsset,
  entry,
}: PreviewTemplateComponentProps): JSX.Element => {
  const props = useMemo(() => {
    const props: HomeProps = deepParseHtml(entry.get("data").toJS());

    props.linkHeroes = props.linkHeroes.filter((hero) => !!hero.label && !!hero.bgColor);
    props.landingTiles = props.landingTiles.filter((tile) => !!tile.heading && !!tile.bgImg);
    props.linkCards = props.linkCards.filter((card) => !!card.label && !!card.bgColor);
    props.quoteHeroes = props.quoteHeroes.filter((hero) => !!hero.label && !!hero.bgColor);

    props.presidentsMessage.img = getAsset(props.presidentsMessage.img as string).toString();
    props.landingTiles = props.landingTiles.map((tile) => ({
      ...tile,
      bgImg: getAsset(tile.bgImg as string).toString(),
    }));
    props.linkCards = props.linkCards.map((card) => ({
      ...card,
      bgImg: getAsset(card.bgImg as string).toString(),
    }));

    return props;
  }, [entry, getAsset]);

  return <Home {...props} />;
};

export default HomePreview;
