import { PreviewTemplateComponentProps } from "netlify-cms-core";
import { FC, useMemo } from "react";

import { InstitutionListProps, InstitutionList } from "@/features/institution-list";
import { deepParseHtml } from "@/utils/deep-parse-html";

const InstitutionListPreview: FC<PreviewTemplateComponentProps> = ({
  getAsset,
  entry,
}: PreviewTemplateComponentProps): JSX.Element => {
  const props = useMemo(() => {
    const props: InstitutionListProps = deepParseHtml(entry.get("data").toJS());

    props.categories = props.categories ?? [{}];
    props.categories = props.categories.map((category) => {
      category.heading = category.heading ?? "Enter header";
      category.institutionList = category.institutionList ?? [{}];
      category.institutionList = category.institutionList.map((institution) => {
        institution.name = institution.name ?? "Enter name";
        institution.descriptionMd = institution.descriptionMd ?? "Enter title";
        institution.img = getAsset(institution.img as string).toString();
        return institution;
      });
      return category;
    });

    return props;
  }, [entry, getAsset]);

  return <InstitutionList {...props} />;
};

export default InstitutionListPreview;
