import { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  hideDivider?: boolean;
}

const ContentLayout: FC<Props> = ({ children, hideDivider = false }: Props): JSX.Element => {
  return (
    <div className={"pt-[3.7em]"}>
      {children}
      {!hideDivider && <div className={"bg-lightBlue mt-[5em] pb-[7em]"} />}
    </div>
  );
};

export default ContentLayout;
