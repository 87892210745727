import { FC, useMemo } from "react";

import { CmsImage, CmsLink, Html, PageTitleCard, ParallaxTile } from "@/components/elements";
import { ContentContainer, ContentLayout } from "@/components/layout";
import { CmsImageData } from "@/types/cms-image-data";
import { getGatsbyImageData } from "@/utils/get-gatsby-image-data";
import { mapJustify } from "@/utils/map-justify";

export interface StoryProps {
  bgImg: CmsImageData;
  pageTitle: string;
  heading: string;
  html: string;
  related: {
    bgColor: string;
    color: string;
    borderColor: string;
    url: string;
    textAlignment: "left" | "center" | "right";
    textMd: string;
  }[];
  descr?: string;
}

const Main: FC<StoryProps> = ({
  bgImg,
  pageTitle,
  html,
  heading,
  related,
}: StoryProps): JSX.Element => {
  const cmsBgImg = useMemo(() => getGatsbyImageData(bgImg), [bgImg]);
  const contentAlignments = useMemo(
    () => related.map(({ textAlignment }) => mapJustify(textAlignment)) ?? [],
    [related]
  );

  return (
    <ContentLayout>
      <ParallaxTile
        bgSlot={
          <CmsImage
            objectFit={"cover"}
            objectPosition={"top"}
            className={"h-[25.6.8em] md:min-h-[25.6em] w-full object-cover object-top"}
            image={cmsBgImg}
            alt={pageTitle}
          />
        }
        fgSlot={
          <div className={"absolute bottom-0 container lg:max-w-[62.5em] px-5"}>
            <PageTitleCard pageTitle={pageTitle} bgColor={"white"} textColor={"black"} />
          </div>
        }
      />
      <ContentContainer>
        <h2 className={"h1"}>{heading}</h2>
        <article>
          <Html>{html}</Html>
        </article>
        <section className={"mt-10 grid grid-cols-1 md:grid-cols-3 gap-5"}>
          {related.map(({ textMd, color, bgColor, borderColor, url }, index) => (
            <CmsLink key={url} href={url}>
              <Html
                noStyle
                key={index}
                className={"flex items-center font-medium text-[14px] w-full min-h-[150px] p-3"}
                style={{
                  color,
                  justifyContent: contentAlignments[index],
                  backgroundColor: bgColor,
                  border: `1px solid ${borderColor}`,
                  overflowWrap: "anywhere",
                }}
              >
                {textMd}
              </Html>
            </CmsLink>
          ))}
        </section>
      </ContentContainer>
    </ContentLayout>
  );
};

export default Main;
