import { FC, useCallback } from "react";

import { ID_SKIP_TO_MAIN } from "@/types/constants";

interface Props {
  targetId: string;
}

const SkipToMain: FC<Props> = ({ targetId }: Props): JSX.Element => {
  const skipTo = useCallback(() => {
    const element = document.getElementById(targetId);

    if (element) {
      element.focus();
    } else {
      console.error(`Provided targetId doesn't exist in DOM: ${targetId}.`);
    }
  }, [targetId]);

  return (
    <div
      className={
        "absolute z-[9999] right-1/2 mr-[-5.6em] pointer-events-none focus:pointer-events-auto"
      }
    >
      <button
        id={ID_SKIP_TO_MAIN}
        title={"Skip to main content"}
        className={
          "p-4 bg-white rounded-lg cursor-pointer opacity-0 pointer-events-none focus:opacity-100 focus:pointer-events-auto"
        }
        tabIndex={0}
        onClick={skipTo}
      >
        <span className={"text-lightBlue"}>Skip to Main Content</span>
      </button>
    </div>
  );
};

export default SkipToMain;
