import { CSSProperties, FC } from "react";

interface Props {
  children?: string;
  as?: "div" | "span";
  className?: string;
  noStyle?: boolean;
  style?: CSSProperties;
}

const Html: FC<Props> = ({
  as = "div",
  children = "",
  noStyle,
  className,
  style,
}: Props): JSX.Element => {
  const classes = `${noStyle ? "" : "md"} ${className ?? ""}`;

  if (as === "div") {
    return <div style={style} className={classes} dangerouslySetInnerHTML={{ __html: children }} />;
  } else {
    return (
      <span style={style} className={classes} dangerouslySetInnerHTML={{ __html: children }} />
    );
  }
};

export default Html;
