import { FC, useMemo } from "react";

import { CmsImage, CmsLink, Html } from "@/components/elements";
import { ContentContainer, ContentLayout } from "@/components/layout";
import { CmsImageData } from "@/types/cms-image-data";
import { formatDateLong } from "@/utils/format-date-long";
import { getGatsbyImageData } from "@/utils/get-gatsby-image-data";

export interface BlogProps {
  pageTitle: string;
  entries: {
    created: Date;
    heading: string;
    headingColor: string;
    textColor: string;
    bgColor: string;
    textMd: string;
    url?: string;
    img?: CmsImageData;
  }[];
  descr?: string;
}

const Blog: FC<BlogProps> = ({ pageTitle, entries }: BlogProps): JSX.Element => {
  const cmsImages = useMemo(() => entries.map(({ img }) => getGatsbyImageData(img)), [entries]);

  return (
    <ContentLayout>
      <ContentContainer>
        <h1 className={"mb-0 text-[2.5em]"}>{pageTitle}</h1>
      </ContentContainer>
      {entries.map(
        ({ created, heading, headingColor, textColor, bgColor, textMd, img, url }, index) => (
          <article className={"pt-[1em]"} key={`Blog-${created}`}>
            <ContentContainer bgColor={bgColor}>
              <div className={"tracking-[0.3em] text-xl font-bold uppercase"}>
                {formatDateLong(created)}
              </div>
              {img && (
                <div className={"mt-10"}>
                  <figure className={"w-full h-[12.5em]"}>
                    <CmsImage
                      objectFit={"cover"}
                      className={"w-full h-[12.5em]"}
                      alt={heading}
                      image={cmsImages[index]}
                    />
                  </figure>
                </div>
              )}
              <h2 className={"py-2.5"} style={{ color: headingColor }}>
                {url ? <CmsLink href={url}>{heading}</CmsLink> : heading}
              </h2>
              <Html className={"alternate-links pb-[2em]"} style={{ color: textColor }}>
                {textMd}
              </Html>
            </ContentContainer>
          </article>
        )
      )}
    </ContentLayout>
  );
};

export default Blog;
