import { FC, ReactNode, useCallback, useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

import { useOnFirstRender } from "@/hooks";
import { zIndex } from "@/types/constants";

interface Props {
  children: ReactNode[];
  intervalSeconds?: number;
  hasMiniControls?: boolean;
}

const activeToggleClasses = "bg-white hover:bg-slate-400 active:bg-slate-600 focus:bg-white";
const toggleClasses = "bg-slate-400 hover:bg-white active:bg-slate-600 focus:bg-slate-400";

const Carousel: FC<Props> = ({
  children,
  intervalSeconds = 999,
  hasMiniControls = false,
}: Props): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [intervalId, setIntervalId] = useState<number>();

  const resetInterval = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }

    setIntervalId(
      setInterval(() => {
        setActiveIndex((prevState) => {
          const nextIndex = prevState + 1;
          return nextIndex === children.length ? 0 : nextIndex;
        });
      }, intervalSeconds * 1000 + Math.random() * 2000) as unknown as number
    );
  }, [children.length, intervalId, intervalSeconds]);

  const choosePrevTile = useCallback(() => {
    setActiveIndex((prevState) => {
      const prevIndex = prevState - 1;
      return prevIndex < 0 ? children.length - 1 : prevIndex;
    });

    resetInterval();
  }, [children.length, resetInterval]);

  const chooseNextTile = useCallback(() => {
    setActiveIndex((prevState) => {
      const nextIndex = prevState + 1;
      return nextIndex === children.length ? 0 : nextIndex;
    });

    resetInterval();
  }, [children.length, resetInterval]);

  const chooseTile = useCallback(
    (index: number) => {
      if (index === activeIndex) {
        return;
      }

      setActiveIndex(index);
      resetInterval();
    },
    [activeIndex, resetInterval]
  );

  const isActiveIndex = useCallback(
    (index: number): boolean => activeIndex === index,
    [activeIndex]
  );

  useOnFirstRender(() => {
    resetInterval();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  });

  return (
    <div className={"relative w-full h-full"}>
      {["left", "right"].map((direction) => (
        <div
          key={`chevron-${direction}`}
          className={"absolute hidden lg:flex h-full w-auto items-center"}
          style={{
            [direction]: 100,
            zIndex: zIndex.background + children.length + 1,
            display: hasMiniControls ? "none" : undefined,
          }}
        >
          <div
            className={
              "text-white hover:text-slate-400 hover:scale-110 focus:text-slate-800 duration-300"
            }
          >
            {direction === "left" ? (
              <button title={"Go to previous tile of carousel"} onClick={choosePrevTile}>
                <BsChevronLeft size={"3em"} />
              </button>
            ) : (
              <button title={"Go to next tile of carousel"} onClick={chooseNextTile}>
                <BsChevronRight size={"3em"} />
              </button>
            )}
          </div>
        </div>
      ))}
      {children.map((ChildNode, index) => (
        <section
          key={index}
          className={"w-full h-full bg-tint text-white absolute transition-all duration-[1.4s]"}
          style={{
            zIndex: zIndex.background + index,
            transform: "translateZ(0)",
            opacity: isActiveIndex(index) ? 1 : 0,
            visibility: isActiveIndex(index) ? "visible" : "hidden",
            willChange: "opacity, transform",
          }}
          aria-hidden={!isActiveIndex(index)}
        >
          {ChildNode}
        </section>
      ))}
      <div
        className={"absolute flex w-full bottom-0 justify-center items-center pb-10"}
        style={{ zIndex: children.length + 1 }}
      >
        <button
          className={"block lg:hidden mr-5"}
          title={"Go to previous tile of carousel"}
          onClick={choosePrevTile}
          style={{ display: hasMiniControls ? "block" : undefined }}
        >
          <BsChevronLeft className={"text-white"} size={"2em"} />
        </button>
        {children.map((_, index) => (
          <button
            key={index}
            title={`Go to tile ${index + 1} of ${children.length} of carousel`}
            className={`h-[6px] w-[6px] rounded-full mx-2 hover:scale-150 duration-300 ${
              isActiveIndex(index) ? activeToggleClasses : toggleClasses
            }`}
            onClick={() => chooseTile(index)}
          />
        ))}
        <button
          className={"block lg:hidden ml-5"}
          title={"Go to next tile of carousel"}
          onClick={chooseNextTile}
          style={{ display: hasMiniControls ? "block" : undefined }}
        >
          <BsChevronRight className={"text-white"} size={"2em"} />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
