import { PreviewTemplateComponentProps } from "netlify-cms-core";
import { FC, useMemo } from "react";

import { Statement, StatementProps } from "@/features/statement";
import { deepParseHtml } from "@/utils/deep-parse-html";

const StatementPreview: FC<PreviewTemplateComponentProps> = ({
  getAsset,
  entry,
}: PreviewTemplateComponentProps): JSX.Element => {
  const props = useMemo(() => {
    const props: StatementProps = deepParseHtml(entry.get("data").toJS());

    props.pageTitle = props.pageTitle || "Enter Page Title";
    props.heading = props.heading || "Enter Heading";
    props.html = props.html || (props as any).body || "Enter Main Content";
    props.bgImg = getAsset(props.bgImg as string).toString();
    props.related = props.related ?? [];

    props.related = props.related.map((related) => {
      related.url = "/#";
      return related;
    });

    return props;
  }, [entry, getAsset]);

  return <Statement {...props} />;
};

export default StatementPreview;
