import { useViewportScroll } from "framer-motion";
import { useLayoutEffect, useState } from "react";

export function useScrollWaypoint(
  yPos: number,
  options?: { initialValue?: boolean; onChange?: (isPassedYPos: boolean) => void }
): { isPassedYPos: boolean } {
  const { scrollY } = useViewportScroll();
  const [isPassedYPos, setIsPassedYPos] = useState(options?.initialValue ?? false);

  useLayoutEffect(() => {
    const unsub = scrollY.onChange((yVal) => {
      if (yVal > yPos) {
        setIsPassedYPos(true);
        options?.onChange?.(true);
      } else {
        if (isPassedYPos) {
          setIsPassedYPos(false);
          options?.onChange?.(false);
        }
      }
    });

    return () => unsub();
  }, [options, isPassedYPos, scrollY, yPos]);

  return {
    isPassedYPos,
  };
}
