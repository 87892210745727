import { FC, useMemo } from "react";

import { CmsLink } from "@/components/elements";

interface Props {
  children: string;
  as?: "a" | "button";
  href?: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  title?: string;
  isSmall?: boolean;
  isDark?: boolean;
  onKeyDown?: (event: any) => void;
  label?: string;
}

const Button: FC<Props> = ({
  children,
  as = "button",
  type = "button",
  title = children,
  isDark = false,
  isSmall = false,
  href,
  onClick,
  onKeyDown,
  label,
}: Props): JSX.Element => {
  const className = useMemo(() => {
    let classes = "button";

    if (isDark) {
      classes += " dark";
    }

    return classes;
  }, [isDark]);

  if (as === "button") {
    return (
      <button
        title={title}
        className={className}
        style={{ fontSize: isSmall ? "0.7em" : "1em" }}
        type={type}
        onClick={onClick}
        onKeyDown={onKeyDown}
        aria-label={label}
      >
        {children}
      </button>
    );
  } else {
    return (
      <CmsLink title={title} href={href ?? ""} onKeyDown={onKeyDown} label={label}>
        <div className={"inline-block"} style={{ height: isSmall ? "2.5em" : "3em" }}>
          <div className={className} style={{ fontSize: isSmall ? "0.9em" : "1em" }}>
            {children}
          </div>
        </div>
      </CmsLink>
    );
  }
};

export default Button;
