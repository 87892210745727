import { graphql, PageProps } from "gatsby";
import { FC } from "react";

import { PageMeta } from "@/components/elements";
import { ErrorLayout, MainLayout } from "@/components/layout";
import { NavbarLink } from "@/features/navbar";

interface QueryResult {
  navbar: {
    frontmatter: {
      navbarLinks: NavbarLink[];
    };
  };
}

const NotFoundPage: FC<PageProps<QueryResult>> = ({ data }: PageProps<QueryResult>) => {
  const { navbarLinks } = data.navbar.frontmatter;

  return (
    <MainLayout navbarLinks={navbarLinks}>
      <PageMeta title={"Page not found"} category={"Error"} noIndex />
      <ErrorLayout
        hero={"OOPS!"}
        heading={"404 error - Page Not Found"}
        text={"The page you are looking for may have moved or is no longer available."}
      />
    </MainLayout>
  );
};

export const query = graphql`
  query NotFoundPageQuery {
    navbar: markdownRemark(fields: { collection: { eq: "navbar" } }) {
      frontmatter {
        navbarLinks {
          label
          url
        }
      }
    }
  }
`;

export default NotFoundPage;
