import { FC } from "react";

import { Html } from "@/components/elements";

export interface LegalInfoProps {
  pageTitle: string;
  contentMd: string;
  color: string;
  bgColor: string;
  disclaimerMd?: string;
  descr?: string;
}

const LegalInfo: FC<LegalInfoProps> = ({
  pageTitle,
  contentMd,
  color,
  bgColor,
  disclaimerMd,
}: LegalInfoProps): JSX.Element => {
  return (
    <div>
      <div className={"h-[21.25em] flex items-center"} style={{ backgroundColor: bgColor, color }}>
        <div className={"container mx-auto"}>
          <h1 className={"text-[1.75em] sm:text-[2.25em] px-5 md:px-2"}>{pageTitle}</h1>
        </div>
      </div>
      <section>
        <Html className={"max-w-[44em] mx-auto my-[5em] px-5"}>{contentMd}</Html>
      </section>
      {disclaimerMd && (
        <section
          className={"bg-blue container mx-auto min-h-[25em] flex justify-center items-center"}
          style={{ backgroundColor: bgColor }}
        >
          <Html className={"max-w-[44em] px-5"} style={{ color }}>
            {disclaimerMd}
          </Html>
        </section>
      )}
    </div>
  );
};

export default LegalInfo;
