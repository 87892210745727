import { PreviewTemplateComponentProps } from "netlify-cms-core";
import { FC, useMemo } from "react";

import { PersonnelList, PersonnelListProps } from "@/features/personnel-list";
import { deepParseHtml } from "@/utils/deep-parse-html";

const PersonnelListPreview: FC<PreviewTemplateComponentProps> = ({
  getAsset,
  entry,
}: PreviewTemplateComponentProps): JSX.Element => {
  const props = useMemo(() => {
    const props: PersonnelListProps = deepParseHtml(entry.get("data").toJS());

    props.sections = props.sections ?? [{}];
    props.sections = props.sections.map((section) => {
      section.heading = section.heading ?? "Enter header";
      section.personnelList = section.personnelList ?? [{}];
      section.personnelList = section.personnelList.map((person) => {
        person.name = person.name ?? "Enter name";
        person.title = person.title ?? "Enter title";
        person.img = getAsset(person.img as string).toString();
        return person;
      });
      return section;
    });

    return props;
  }, [entry, getAsset]);

  return <PersonnelList {...props} />;
};

export default PersonnelListPreview;
