import { FC, useMemo } from "react";

interface Props {
  status: string;
  transcript: string;
  isSpeechFinal: boolean;
  errorCode: string;
  isListening: boolean;
  isBrowserSupported: boolean;
}

const VoiceStatus: FC<Props> = ({
  status,
  isBrowserSupported,
  isListening,
}: Props): JSX.Element => {
  const text = useMemo(() => {
    if (status === "initial") {
      return "";
    }

    if (!isBrowserSupported) {
      return "Unsupported browser";
    }

    if (isListening) {
      return "Listening";
    } else {
      return "Waiting";
    }
  }, [isBrowserSupported, isListening, status]);

  return <div className={"text-[12px] text-center"}>{text}</div>;
};

export default VoiceStatus;
