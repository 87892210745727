import { motion, useAnimation, Variants } from "framer-motion";
import { navigate } from "gatsby";
import {
  FC,
  FormEvent,
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { Button, CmsLink } from "@/components/elements";
import { NavbarLink } from "@/features/navbar";
import { NO_SCROLL_ATTRIBUTE } from "@/types/constants";

interface Props {
  isOpen: boolean;
  navbarLinks: NavbarLink[];
}

const MobileMenu: FC<Props> = (
  { isOpen, navbarLinks }: Props,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
  const animate = useAnimation();
  const [searchTerms, setSearchTerms] = useState("");

  const submitSearch = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      setSearchTerms("");

      navigate("/search", {
        state: {
          term: searchTerms,
        },
      });
    },
    [searchTerms]
  );

  const acceptSearchTerm = useCallback(
    (event: FormEvent<HTMLInputElement>) => setSearchTerms(event.currentTarget.value),
    []
  );

  useEffect(() => {
    if (isOpen) {
      document.body.setAttribute(NO_SCROLL_ATTRIBUTE, "");
      animate.start("visible").then(async () => {
        await animate.start("open");
      });
    } else {
      document.body.removeAttribute(NO_SCROLL_ATTRIBUTE);
      animate.start("closed").then(async () => {
        await animate.start("hidden");
      });
    }
  }, [animate, isOpen]);

  return (
    <motion.div
      ref={ref}
      variants={variants}
      animate={animate}
      initial={{ translateX: "100%" }}
      className={
        "fixed bg-blue h-screen w-screen z-[9] transition-transform duration-500 linear pt-[3.5em]"
      }
      aria-hidden={!isOpen}
    >
      <div className={"text-white flex justify-center items-center pt-[0.5em]"}>
        <div className={"w-full sm:max-w-[25em] px-2"}>
          {navbarLinks.slice(0, 11).map(({ url, label }) => (
            <div key={label} className={"text-center text-[1.1em] py-1 my-1"}>
              <CmsLink className={"text-white"} href={url}>
                {label}
              </CmsLink>
            </div>
          ))}
          <div className={"mt-5 px-2"}>
            <div className={"text-lg text-slate-200 mb-1"}>Search Site</div>
            <form onSubmit={submitSearch}>
              <input
                className={"w-full h-[2.5em] border-neutral-300 text-black px-1 border rounded"}
                placeholder={"Enter search terms"}
                onChange={acceptSearchTerm}
              />
              <div className={"mt-2 h-[2.5em]"}>
                <Button title={"Search site"} type={"submit"}>
                  Search
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const variants: Variants = {
  visible: {
    visibility: "visible",
    transition: {
      duration: 0,
    },
  },
  open: {
    translateX: "0%",
    transition: {
      duration: 0,
    },
  },
  closed: {
    translateX: "100%",
    transition: {
      duration: 0,
    },
  },
  hidden: {
    visibility: "hidden",
    transition: {
      duration: 0,
      delay: 0.5,
    },
  },
};

export default forwardRef<HTMLDivElement, Props>(
  MobileMenu as ForwardRefRenderFunction<HTMLDivElement, Props>
);
