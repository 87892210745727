import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks";
import { FC, useMemo } from "react";

import { Button, CmsImage, CmsLink, Html } from "@/components/elements";
import { CmsImageData } from "@/types/cms-image-data";
import { getGatsbyImageData } from "@/utils/get-gatsby-image-data";

export interface LandingTileProps {
  bgImg: CmsImageData;
  isTinted: boolean;
  heading: string;
  textMd: string;
  url: string;
  linkLabel: string;
  logoImg?: CmsImageData;
  subHeading?: string;
}

const LandingTile: FC<LandingTileProps> = ({
  isTinted,
  heading,
  logoImg,
  bgImg,
  linkLabel,
  url,
  textMd,
  subHeading,
}: LandingTileProps): JSX.Element => {
  const { gatsbyLogoImg, gatsbyBgImg } = useMemo(() => {
    return {
      gatsbyLogoImg:
        typeof logoImg === "string"
          ? logoImg
          : (logoImg as FileNode)?.childImageSharp
          ? getGatsbyImageData(logoImg)
          : undefined,
      gatsbyBgImg: getGatsbyImageData(bgImg),
    };
  }, [bgImg, logoImg]);

  return (
    <div className={"grid"}>
      <CmsImage
        image={gatsbyBgImg}
        alt={heading}
        title={heading}
        className={"h-[55em] portrait:max-h-screen landscape:max-h-[55em] w-full object-cover"}
        style={{
          gridArea: "1/1",
        }}
      />
      {isTinted && (
        <div
          className={
            "relative bg-tint h-[55em] portrait:max-h-screen landscape:max-h-[55em] w-full opacity-50"
          }
          style={{
            gridArea: "1/1",
          }}
        />
      )}
      <div
        className={"relative portrait:max-h-screen landscape:max-h-[55em] place-items-center grid"}
        style={{
          gridArea: "1/1",
        }}
      >
        <div
          className={"w-full sm:w-[31.25em] px-5 sm:px-0 relative right-0 xl:right-[15%]"}
          style={{
            textShadow: "rgb(0 0 0 / 40%) 0px 4px 5px",
          }}
        >
          {gatsbyLogoImg && (
            <CmsLink href={url}>
              <CmsImage className={"h-[6em] w-[6em] mb-2"} image={gatsbyLogoImg} alt={heading} />
            </CmsLink>
          )}
          <h2 className={"font-bold text-[2em] sm:text-[2.75em] text-white leading-[1em] my-1"}>
            {heading}
          </h2>
          {subHeading && (
            <h3 className={"italic font-bold text-[1.25em] sm:text-[1.5em] text-white mt-3 mb-4"}>
              {subHeading}
            </h3>
          )}
          <Html noStyle className={"font-bold text-[1.15em] text-white my-5 alternate-links"}>
            {textMd}
          </Html>
          <div className={"text-white font-bold hover:underline w-fit mt-10"}>
            <Button
              isSmall
              title={linkLabel}
              label={`${linkLabel} on story: ${heading}`}
              as={"a"}
              href={url}
            >
              {linkLabel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingTile;
