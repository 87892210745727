import { graphql, PageProps } from "gatsby";
import { FC, useCallback, useEffect, useState } from "react";
import { Configure, Hits, InstantSearch, SearchBox, VoiceSearch } from "react-instantsearch-dom";

import { PageMeta } from "@/components/elements";
import { ContentContainer, ContentLayout, MainLayout } from "@/components/layout";
import { NavbarLink } from "@/features/navbar";
import { SearchResult, VoiceStatus } from "@/features/search";
import { ALGOLIA_INDEX_PAGES, SEARCH_CLIENT } from "@/types/constants";

interface QueryResult {
  navbar: {
    frontmatter: {
      navbarLinks: NavbarLink[];
    };
  };
}

const Search: FC<PageProps<QueryResult>> = ({
  location,
  data,
}: PageProps<QueryResult>): JSX.Element => {
  const { navbarLinks } = data.navbar.frontmatter;
  const [searchTerm, setSearchTerm] = useState("");

  const acceptSearchTerm = useCallback(({ query }: { query: string }) => {
    setSearchTerm(query);
  }, []);

  useEffect(() => {
    const locationWithState = location as { state?: { term?: string } };
    setSearchTerm(decodeURIComponent(locationWithState.state?.term ?? ""));
  }, [location]);

  return (
    <MainLayout navbarLinks={navbarLinks}>
      <PageMeta title={"Site search"} />
      <ContentLayout>
        <ContentContainer>
          <InstantSearch
            indexName={ALGOLIA_INDEX_PAGES}
            searchClient={SEARCH_CLIENT}
            searchState={{ query: searchTerm }}
            onSearchStateChange={acceptSearchTerm}
          >
            <Configure hitsPerPage={10} />
            <div className={"grid grid-cols-4"}>
              <div className={"col-span-3"}>
                <h1 className={"mt-10 mb-2"}>Site Search</h1>
                <div className={"mb-5"}>Enter search terms below</div>
              </div>
              <div className={"flex justify-end items-end mb-2 col-span-1"}>
                <VoiceSearch statusComponent={VoiceStatus} />
              </div>
            </div>
            <div className={"mb-5"}>
              <SearchBox />
            </div>
            <Hits hitComponent={SearchResult} />
          </InstantSearch>
        </ContentContainer>
      </ContentLayout>
    </MainLayout>
  );
};

export const query = graphql`
  query SearchPageQuery {
    navbar: markdownRemark(fields: { collection: { eq: "navbar" } }) {
      frontmatter {
        navbarLinks {
          label
          url
        }
      }
    }
  }
`;

export default Search;
