import { FC } from "react";
import { Helmet } from "react-helmet";

import config from "@/config";
import { formatPageTitle } from "@/utils/format-page-title";

interface Props {
  title: string;
  description?: string;
  category?: string;
  noIndex?: boolean;
  isTemplate?: boolean;
}

const PageMeta: FC<Props> = ({
  title,
  description,
  category,
  noIndex,
  isTemplate,
}: Props): JSX.Element => {
  const { GTAG, ENV } = config;
  const shouldRenderGtagScript = ENV !== "development" && !isTemplate;

  return (
    <Helmet>
      {shouldRenderGtagScript && (
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${GTAG}`} />
      )}
      {shouldRenderGtagScript && (
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GTAG}');`}
        </script>
      )}
      <title>{formatPageTitle(title, category)}</title>
      <meta name={"description"} content={description} />
      <meta name={"robots"} content={noIndex ? "noindex" : "index, follow"} />
    </Helmet>
  );
};

export default PageMeta;
