import { FC } from "react";

import { CmsLink } from "@/components/elements";

export interface LinkHeroProps {
  label: string;
  url: string;
  color: string;
  bgColor: string;
}

const LinkHero: FC<LinkHeroProps> = ({
  label,
  url,
  bgColor,
  color,
}: LinkHeroProps): JSX.Element => {
  return (
    <CmsLink title={label} href={url} className={"group"}>
      <div
        className={
          "flex justify-center items-center py-1 transition-scale duration-200 ease-in-out group-hover:scale-[1.02]"
        }
        style={{ backgroundColor: bgColor }}
      >
        <span className={"font-bold text-[1.3em] md:text-1xl"} style={{ color }}>
          {label}
        </span>
      </div>
    </CmsLink>
  );
};

export default LinkHero;
