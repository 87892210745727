import { FC, useMemo } from "react";

import { CmsImage } from "@/components/elements";
import { CmsImageData } from "@/types/cms-image-data";
import { getGatsbyImageData } from "@/utils/get-gatsby-image-data";

interface Props {
  size: number;
  img: CmsImageData;
  borderColor?: string;
}

const PersonPicture: FC<Props> = ({
  img,
  size,
  borderColor = "var(--colorLightBlue)",
}: Props): JSX.Element => {
  const { gatsbyImg } = useMemo(() => {
    return {
      gatsbyImg: getGatsbyImageData(img),
    };
  }, [img]);

  return (
    <div
      className={"rounded-full border-x-[0.4em] border-y-[0.4em] overflow-hidden mb-5 md:mb-0"}
      style={{
        borderColor,
        transform: "translateZ(0)",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px 0px",
        height: `${size}em`,
        width: `${size}em`,
      }}
    >
      <CmsImage
        className={"w-full h-full"}
        objectFit={"cover"}
        objectPosition={"top"}
        image={gatsbyImg}
        alt={"President of NADOHE"}
      />
    </div>
  );
};

export default PersonPicture;
