import { graphql, PageProps } from "gatsby";
import { FC } from "react";

import { PageMeta } from "@/components/elements";
import { MainLayout } from "@/components/layout";
import { Index, IndexProps } from "@/features/index";
import { NavbarLink } from "@/features/navbar";

interface QueryResult {
  navbar: {
    frontmatter: {
      navbarLinks: NavbarLink[];
    };
  };
  index: {
    frontmatter: IndexProps;
  };
}

const IndexTemplate: FC<PageProps<QueryResult>> = ({
  data,
}: PageProps<QueryResult>): JSX.Element => {
  const { pageTitle, bannerImg, descr, sections } = data.index.frontmatter;
  const { navbarLinks } = data.navbar.frontmatter;

  return (
    <MainLayout navbarLinks={navbarLinks}>
      <PageMeta isTemplate title={pageTitle} category={"Stories"} description={descr} />
      <Index
        pageTitle={pageTitle}
        bannerImg={bannerImg}
        sections={sections.map((section) => ({
          ...section,
          createdDate: new Date(section.createdDate),
        }))}
      />
    </MainLayout>
  );
};

export const query = graphql`
  query IndexesPageQuery($id: String) {
    navbar: markdownRemark(fields: { collection: { eq: "navbar" } }) {
      frontmatter {
        navbarLinks {
          label
          url
        }
      }
    }
    index: markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        descr
        bannerImg {
          childImageSharp {
            gatsbyImageData(quality: 100, height: 500)
          }
        }
        sections {
          createdDate
          img {
            childImageSharp {
              gatsbyImageData(height: 300)
            }
          }
          heading
          url
          textMd
        }
      }
    }
  }
`;

export default IndexTemplate;
