import { FC, useMemo } from "react";

import { CmsImage, CmsLink, Html } from "@/components/elements";
import { ContentContainer, ContentLayout } from "@/components/layout";
import { CmsImageData } from "@/types/cms-image-data";
import { formatDateLong } from "@/utils/format-date-long";
import { getGatsbyImageData } from "@/utils/get-gatsby-image-data";

export interface IndexProps {
  bannerImg: CmsImageData;
  pageTitle: string;
  sections: {
    createdDate: Date;
    img: CmsImageData;
    heading: string;
    url: string;
    textMd: string;
  }[];
  descr?: string;
}

const Main: FC<IndexProps> = ({ bannerImg, pageTitle, sections }: IndexProps): JSX.Element => {
  const { cmsBannerImg, cmsSectionImages } = useMemo(
    () => ({
      cmsSectionImages: sections.map(({ img }) => getGatsbyImageData(img)),
      cmsBannerImg: getGatsbyImageData(bannerImg),
    }),
    [bannerImg, sections]
  );

  return (
    <ContentLayout>
      <ContentContainer>
        <CmsImage
          alt={pageTitle}
          image={cmsBannerImg}
          className={"w-full h-[250px] mt-5"}
          objectFit={"cover"}
          objectPosition={"top"}
        />
        <h1>{pageTitle}</h1>
        <article className={"mt-10"}>
          <div className={"grid grid-cols-1 lg:grid-cols-2 gap-[1em] lg:gap-[3.5em]"}>
            {sections.map(({ heading, createdDate, textMd, url }, index) => (
              <div key={heading} style={{ overflowWrap: "anywhere" }}>
                <CmsImage
                  alt={heading}
                  image={cmsSectionImages[index]}
                  className={"w-full h-[150px]"}
                  objectFit={"cover"}
                  objectPosition={"top"}
                />
                <CmsLink title={heading} href={url}>
                  <h2 className={"mb-1.5"}>{heading}</h2>
                </CmsLink>
                <div className={"mb-3"}>{formatDateLong(createdDate)}</div>
                <Html>{textMd}</Html>
              </div>
            ))}
          </div>
        </article>
      </ContentContainer>
    </ContentLayout>
  );
};

export default Main;
