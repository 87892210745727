import { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  bgColor?: string;
}

const ContentContainer: FC<Props> = ({ children, bgColor }: Props): JSX.Element => {
  return (
    <div
      className={"container mx-auto lg:max-w-[62.5em] px-5"}
      style={{ backgroundColor: bgColor }}
    >
      {children}
    </div>
  );
};

export default ContentContainer;
