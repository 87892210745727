import algoliasearch from "algoliasearch/lite";

import config from "@/config";

export const isBrowser = typeof window !== "undefined";

export const KEY_IS_PREVIEW = "X_IS_PREVIEW";
export const KEY_USERWAY_CONFIG = "_userway_config";
export const INFO_EMAIL = "info@nadohe.org";
export const ID_MAIN = "ID_MAIN";
export const ID_SKIP_TO_MAIN = "ID_SKIP_TO_MAIN";
export const NO_SCROLL_ATTRIBUTE = "noScroll";
export const ALGOLIA_INDEX_PAGES = "Pages";
export const SEARCH_CLIENT = algoliasearch(config.ALGOLIA_APP_ID, config.ALGOLIA_API_KEY);

export const zIndex = {
  background: 0,
  foreground: 5,
  dropdown: 10,
  dialogue: 20,
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const userwayConfig: never = {
  /* uncomment the following line to override default position*/
  position: "3",
  /* uncomment the following line to override default size (values: small, large)*/
  size: "large",
  /* uncomment the following line to override default language (e.g., fr, de, es, he, nl, etc.)*/
  /* language: 'en-US', */
  /* uncomment the following line to override color set via widget (e.g., #053f67)*/
  color: "#ed2127",
  /* uncomment the following line to override type set via widget(1=person, 2=chair, 3=eye)*/
  type: "1",
  /* uncomment the following line to override support on mobile devices*/
  mobile: true,
  account: "TF9EjYxO1g",
} as unknown as never;
