import { FC } from "react";

import ContactDetails from "./ContactDetails";
import SocialLinks from "./SocialLinks";

const sections = [ContactDetails, SocialLinks];

const Main: FC = (): JSX.Element => {
  return (
    <div className={"mt-14 mb-32"}>
      <div className={"grid grid-cols-1 lg:grid-cols-2 text-darkGray"}>
        {sections.map((FooterSection, index) => (
          <div key={index} className={"flex justify-center px-5"}>
            {<FooterSection />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Main;
