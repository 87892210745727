import { graphql, PageProps } from "gatsby";
import { FC } from "react";

import { PageMeta } from "@/components/elements";
import { MainLayout } from "@/components/layout";
import { Landing, LandingProps } from "@/features/landing";
import { NavbarLink } from "@/features/navbar";

interface QueryResult {
  navbar: {
    frontmatter: {
      navbarLinks: NavbarLink[];
    };
  };
  landing: {
    html: string;
    frontmatter: LandingProps;
  };
}

const LandingTemplate: FC<PageProps<QueryResult>> = ({
  data,
}: PageProps<QueryResult>): JSX.Element => {
  const { html } = data.landing;
  const { pageTitle, bgImg, buttons, descr, color, bgColor } = data.landing.frontmatter;
  const { navbarLinks } = data.navbar.frontmatter;

  return (
    <MainLayout navbarLinks={navbarLinks}>
      <PageMeta isTemplate title={pageTitle} description={descr} />
      <Landing
        pageTitle={pageTitle}
        html={html}
        bgImg={bgImg}
        buttons={buttons}
        color={color}
        bgColor={bgColor}
      />
    </MainLayout>
  );
};

export const query = graphql`
  query LandingPageQuery($id: String) {
    navbar: markdownRemark(fields: { collection: { eq: "navbar" } }) {
      frontmatter {
        navbarLinks {
          label
          url
        }
      }
    }
    landing: markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        descr
        color
        bgColor
        bgImg {
          childImageSharp {
            gatsbyImageData(quality: 100, height: 410)
          }
        }
        buttons {
          label
          url
        }
      }
      html
    }
  }
`;

export default LandingTemplate;
