import { PreviewTemplateComponentProps } from "netlify-cms-core";
import { FC } from "react";

import { Landing, LandingProps } from "@/features/landing";
import { deepParseHtml } from "@/utils/deep-parse-html";

const LandingPreview: FC<PreviewTemplateComponentProps> = ({
  getAsset,
  entry,
}: PreviewTemplateComponentProps): JSX.Element => {
  const props: LandingProps = deepParseHtml(entry.get("data").toJS());

  props.pageTitle = props.pageTitle ?? "Enter page title";
  props.buttons = props.buttons ?? [];
  props.html = props.html ?? (props as any).body ?? "Enter content";

  props.bgImg = getAsset(props.bgImg as string).toString();

  return <Landing {...props} />;
};

export default LandingPreview;
