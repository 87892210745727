import { IGatsbyImageData } from "gatsby-plugin-image";
import { FC, useMemo } from "react";

import { CmsImage, Html, PageTitleCard } from "@/components/elements";
import { ContentContainer, ContentLayout } from "@/components/layout";
import { CmsImageData } from "@/types/cms-image-data";
import { getGatsbyImageData } from "@/utils/get-gatsby-image-data";

export interface InstitutionListProps {
  pageTitle: string;
  categories: {
    heading: string;
    institutionList: {
      img: CmsImageData;
      name: string;
      descriptionMd: string;
    }[];
  }[];
  descr?: string;
}

const InstitutionList: FC<InstitutionListProps> = ({
  pageTitle,
  categories,
}: InstitutionListProps): JSX.Element => {
  const cmsImages = useMemo(() => {
    return categories.reduce(
      (
        collection: { [key: number]: (IGatsbyImageData | string | undefined)[] },
        { institutionList },
        index
      ) => {
        collection[index] = institutionList.map(({ img }) => getGatsbyImageData(img));
        return collection;
      },
      {}
    );
  }, [categories]);

  return (
    <ContentLayout>
      <ContentContainer>
        <div className={"mt-5"}>
          <PageTitleCard pageTitle={pageTitle} bgColor={"var(--colorBlue)"} textColor={"white"} />
        </div>
        {categories.map(({ heading, institutionList }, categoryIndex) => (
          <section key={heading}>
            <h2 className={"h1"}>{heading}</h2>
            <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-10 gap-x-2.5"}>
              {institutionList.map(({ name, descriptionMd }, institutionIndex) => (
                <div key={name} className={"text-center"}>
                  <div className={"w-[19.68em]"}>
                    <CmsImage
                      objectFit={"cover"}
                      objectPosition={"top"}
                      className={"h-[17em] md:h-[13.1em] w-full object-cover object-top"}
                      alt={name}
                      image={cmsImages[categoryIndex][institutionIndex]}
                    />
                  </div>
                  <div className={"text-lg font-bold my-3"}>{name}</div>
                  <Html>{descriptionMd}</Html>
                </div>
              ))}
            </div>
          </section>
        ))}
      </ContentContainer>
    </ContentLayout>
  );
};

export default InstitutionList;
