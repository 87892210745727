import { graphql, PageProps } from "gatsby";
import { FC } from "react";

import { PageMeta } from "@/components/elements";
import { MainLayout } from "@/components/layout";
import { NavbarLink } from "@/features/navbar";
import { PersonnelList, PersonnelListProps } from "@/features/personnel-list";

interface QueryResult {
  navbar: {
    frontmatter: {
      navbarLinks: NavbarLink[];
    };
  };
  personnelList: {
    frontmatter: PersonnelListProps;
  };
}

const ArticleTemplate: FC<PageProps<QueryResult>> = ({
  data,
}: PageProps<QueryResult>): JSX.Element => {
  const { sections, pageTitle, descr } = data.personnelList.frontmatter;
  const { navbarLinks } = data.navbar.frontmatter;

  return (
    <MainLayout navbarLinks={navbarLinks}>
      <PageMeta isTemplate title={pageTitle} description={descr} />
      <PersonnelList pageTitle={pageTitle} sections={sections} />
    </MainLayout>
  );
};

export const query = graphql`
  query PersonnelListPageQuery($id: String) {
    navbar: markdownRemark(fields: { collection: { eq: "navbar" } }) {
      frontmatter {
        navbarLinks {
          label
          url
        }
      }
    }
    personnelList: markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        descr
        sections {
          heading
          personnelList {
            img {
              childImageSharp {
                gatsbyImageData(quality: 75, height: 500)
              }
            }
            name
            title
            contact
          }
        }
      }
    }
  }
`;

export default ArticleTemplate;
