import { Link } from "gatsby";
import { FC, ForwardRefRenderFunction, forwardRef, ReactNode, useMemo } from "react";

interface Props {
  href: string;
  title?: string;
  className?: string;
  children?: ReactNode;
  onKeyDown?: (event: any) => void;
  label?: string;
}

const CmsLink: FC<Props> = (
  { title, children, href, className, onKeyDown, label }: Props,
  ref?: any
): JSX.Element => {
  const { isAbsolute, isFile } = useMemo(() => {
    return {
      isAbsolute: /http(s?):\/\//i.test(href),
      isFile: /\.[0-9a-z]+$/i.test(href),
    };
  }, [href]);

  if (isFile) {
    return (
      <a
        ref={ref}
        className={className}
        title={title}
        href={href}
        target={"_blank"}
        rel={"noreferrer"}
        onKeyDown={onKeyDown}
        aria-label={label}
      >
        {children}
      </a>
    );
  } else if (isAbsolute) {
    return (
      <a
        ref={ref}
        className={className}
        title={title}
        href={href}
        onKeyDown={onKeyDown}
        aria-label={label}
      >
        {children}
      </a>
    );
  } else {
    return (
      <Link ref={ref} title={title} to={href} onKeyDown={onKeyDown} aria-label={label}>
        <span className={className}>{children}</span>
      </Link>
    );
  }
};

export default forwardRef<HTMLAnchorElement, Props>(
  CmsLink as ForwardRefRenderFunction<HTMLAnchorElement, Props>
);
