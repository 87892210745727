import { graphql, PageProps } from "gatsby";
import { FC } from "react";

import { PageMeta } from "@/components/elements";
import { MainLayout } from "@/components/layout";
import { NavbarLink } from "@/features/navbar";
import { Statement, StatementProps } from "@/features/statement";

interface QueryResult {
  navbar: {
    frontmatter: {
      navbarLinks: NavbarLink[];
    };
  };
  statement: {
    frontmatter: Omit<StatementProps, "html">;
    html: string;
  };
}

const StatementTemplate: FC<PageProps<QueryResult>> = ({
  data,
}: PageProps<QueryResult>): JSX.Element => {
  const html = data.statement.html;
  const { bgImg, pageTitle, heading, descr, related } = data.statement.frontmatter;
  const { navbarLinks } = data.navbar.frontmatter;

  return (
    <MainLayout navbarLinks={navbarLinks}>
      <PageMeta isTemplate title={pageTitle} category={"Stories"} description={descr} />
      <Statement
        bgImg={bgImg}
        pageTitle={pageTitle}
        heading={heading}
        html={html}
        related={related ?? []}
      />
    </MainLayout>
  );
};

export const query = graphql`
  query StatementPageQuery($id: String) {
    navbar: markdownRemark(fields: { collection: { eq: "navbar" } }) {
      frontmatter {
        navbarLinks {
          label
          url
        }
      }
    }
    statement: markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        descr
        heading
        bgImg {
          childImageSharp {
            gatsbyImageData(quality: 100, height: 410)
          }
        }
        related {
          color
          bgColor
          textMd
          url
          borderColor
          textAlignment
        }
      }
      html
    }
  }
`;

export default StatementTemplate;
