import { PreviewTemplateComponentProps } from "netlify-cms-core";
import { FC, useMemo } from "react";

import { deepParseHtml } from "@/utils/deep-parse-html";

import Blog, { BlogProps } from "./Main";

const BlogPreview: FC<PreviewTemplateComponentProps> = ({
  getAsset,
  entry,
}: PreviewTemplateComponentProps): JSX.Element => {
  const props = useMemo(() => {
    const props: BlogProps = deepParseHtml(entry.get("data").toJS());

    props.pageTitle = props.pageTitle || "Enter Page Title";
    props.entries = props.entries || [];

    props.entries = props.entries.map((entry) => ({
      ...entry,
      img: getAsset(entry.img as string).toString(),
    }));

    return props;
  }, [getAsset, entry]);

  return <Blog {...props} />;
};

export default BlogPreview;
