import "instantsearch.css/themes/satellite.css";

import { FC } from "react";
import { Snippet } from "react-instantsearch-dom";

import { CmsLink } from "@/components/elements";

interface Props {
  hit: any;
}

const SearchResult: FC<Props> = ({ hit }: Props): JSX.Element => {
  const { slug, title } = hit;

  return (
    <div
      style={{
        overflowWrap: "anywhere",
      }}
    >
      {title && (
        <div>
          {title && (
            <CmsLink href={`/${slug}`} className={"text-[20px]"}>
              {title}
            </CmsLink>
          )}
          <div>
            {window.location.origin}/{slug}
          </div>
        </div>
      )}
      <div className={"mt-3"}>
        <Snippet className={"text-[16px]"} attribute={"extract"} hit={hit} />
      </div>
    </div>
  );
};

export default SearchResult;
